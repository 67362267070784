import React from "react";
import { StyledInputWrapper, StyledTextInput } from "../../../../index.style";
import { InputAdornment } from "@mui/material";
import { specialConditionTypes } from "../../../../../../../../app/costants";
import { formatNumber } from "../../../../../../../../utils/numbers";
import AppContext, { AppContextTypes } from "app/index.ctx";

const Totals = (): React.ReactElement => {
  const {
    investmentAmount,
    outputCurrency,
    csfMinFeeWaived,
    csfSpecialCondition,
    csfSpecialConditionAmount,
    bcCurrency,
    valueBand1,
    valueBand2,
    valueBand3,
    valueBand4,
    csfBand1,
    csfBand2,
    csfBand3,
    csfBand4,
    csfMinFee,
    setCsfTotal,
    setCsfSoko,
    custodyServiceFee,
    exchangeRate,
  } = React.useContext(AppContext) as AppContextTypes;

  let total =
    ((valueBand1 as number) * (csfBand1 as number)) / 100 +
    ((valueBand2 as number) * (csfBand2 as number)) / 100 +
    ((valueBand3 as number) * (csfBand3 as number)) / 100 +
    ((valueBand4 as number) * (csfBand4 as number)) / 100;

  if (custodyServiceFee === 0) {
    total = 0;
  }

  let soko = (() => {
    if (csfSpecialConditionAmount !== undefined && !isNaN(csfSpecialConditionAmount)) {
      return csfSpecialCondition === specialConditionTypes[1].value
        ? -((total * csfSpecialConditionAmount) / 100)
        : -(
          total -
          ((investmentAmount as number) *
            (exchangeRate ?? 1) *
            csfSpecialConditionAmount) /
          10000
        );
    }

    return 0;
  })();

  total = (
    !csfMinFeeWaived && (csfMinFee as number) > total + soko ? csfMinFee : total
  ) as number;

  soko = (() => {
    if (csfMinFeeWaived) {
      return soko;
    }

    return total > (csfMinFee as number) ? soko : 0;
  })();

  React.useEffect(() => {
    if (setCsfTotal) {
      setCsfTotal(total);
    }
    if (setCsfSoko) {
      setCsfSoko(soko);
    }
  }, [soko, total]);

  if (!investmentAmount) return <React.Fragment />;

  return (
    <React.Fragment>
      <StyledInputWrapper>
        <StyledTextInput
          id="csf-sum"
          name="csf-sum"
          label="CSF Amount Sum"
          variant="filled"
          value={total ? formatNumber(total, { decimals: 2 }) : 0.0}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">{outputCurrency}</InputAdornment>
            ),
          }}
        />

        <StyledTextInput
          id="csf-soko"
          name="csf-soko"
          label="CSF Amount SOKO"
          variant="filled"
          value={soko ? formatNumber(soko, { decimals: 2 }) : 0.0}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">{bcCurrency}</InputAdornment>
            ),
          }}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default Totals;
